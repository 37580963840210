.employmentRow {
    display: block;
    margin: 3rem;
    padding: 1rem;
    overflow: visible;
    background-color: #c9c9c9;
    height: 20rem;
    margin-left: auto;
    margin-right: auto;
}
.employmentRowLast {
    display: block;
    padding: 1rem;
    overflow: visible;
    background-color: #c9c9c9;
    height: 20rem;
    margin-left: auto;
    margin-right: auto;
}

.expirience {
    width: 20rem;
    height: auto;
    margin: auto;
}

.my_image{
    border-radius: 10%;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}
.main_body{
    background-color: #e8e8e8;
    min-height: 100vh;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
  }

nav ul li{
    list-style: none;
    display: inline-block;
}

nav{
    background-color: #c9c9c9;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    text-align: center;
    justify-content: center;
    font-weight: 750;
}

nav li a{
    padding: 1px 7px;
    box-sizing: content-box;
}


footer{
    background-color: #c9c9c9;
    text-align: center;
    width: 100%;
    font-size: x-small;
    margin-top: 5%;
}

header{
    display: block;
    background-color: black;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.title{
    text-align: center;
    padding-top: 2rem;
    color: black;
    font-weight: bold;
    margin-bottom: 1%;
    font-size: larger;
    font-family: Arial;
    font-weight: 500;
    text-decoration: underline;
}

.feed_img{
    width: 25%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.feed_heading{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font: serif;
    font-style: oblique;
    font-size: large;
    font-weight: 600;
}
.feed_info{
    text-align: center;
    font-size: medium;
    font: serif;
    font-weight: 500;
}

.feed_more_info{
    padding-top: 10px;
    text-align: center;
    font-size: small;
    font: serif;
    font-weight: 400;
}

.feed_more_info_top{
    padding-top: 40px;
    text-align: center;
    font-size: small;
    font: serif;
    font-weight: 400;
}



.main_about{
    margin-left: 10%;
    margin-right: 10%;
}

.boxes_about{
    display: block;
    background-color: #c9c9c9;
    margin-top: 20px;
}

.questions{
    padding-top: 2%;
    padding-left: 4%;
    font: serif;
    font-style: oblique;
    font-size: small;
    text-decoration: underline;
}

.answers{
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 2%;
    padding-bottom: 5%;
    font: serif;
    font-size: small;
}

.about_top{
    padding: 5%;
    font: serif;
    font-size: small;
}

.project_body{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project_container{
    margin: 10px;
    width: 19rem;
}

.project_pic{
    width: 100%;
    margin-top: 1%;
}

.project_paragraph{
    display: flex;
    background-color: #c9c9c9;
    font-size: small;
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    padding-bottom: 2rem;
}
.project_title{
    text-align: center;
    padding: 10px;
    font-style: oblique;
    background-color: #c9c9c9;
}

.other_body{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.other_box{
    margin: 3%;
    width: 13rem;
    font-size: x-small;
    
}

.other_title{
    padding: 10px;
    text-align: center;
    color: black;
    font-weight: 450;
    font-size: small;
}

.other_title_cert{
    text-align: center;
    color: black;
    font-weight: 450;
    font-size: small;
}

.other_click{
    padding-bottom: 5px;
    text-align: center;
    color: black;
    font-weight: 450;
    font-size: small;
}

.about_block{
    display: block;
    background-color: #c9c9c9;
    margin-bottom: 20px;
}

.lists{
    text-align: left;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
    list-style:outside circle;
    font-weight: 350;
    font-size: small;
}


.footer_icon_box{
    list-style-type: none;
}

.footer_icon{
    width: 4rem;
    display: inline;
    padding: 1%;
}

.footer_text{
    padding-top: 1%;
}


